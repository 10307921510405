const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

export const connect = (wallet) => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    var isWhiteList = false;
    var isDiscount = false;
    var mintCost = "";
    var totalSupply = "";
    var maxSupply = "";
    const CONFIG = await configResponse.json();
    const SmartContractObj = new wallet.web3.eth.Contract(
      abi,
      CONFIG.CONTRACT_ADDRESS
    );
    await SmartContractObj.methods
      .isWhiteList(wallet.account)
      .call()
      .then(function (result) {
        isWhiteList = result;
      });
    await SmartContractObj.methods
      .isDiscount(wallet.account)
      .call()
      .then(function (result) {
        isDiscount = result;
      });
    await SmartContractObj.methods
      .mintCost(wallet.account)
      .call()
      .then(function (result) {
        mintCost = result;
      });
      await SmartContractObj.methods
      .totalSupply()
      .call()
      .then(function (result) {
        totalSupply = result;
      });
      await SmartContractObj.methods
      .maxSupply()
      .call()
      .then(function (result) {
        maxSupply = result;
      });
    dispatch(
      connectSuccess({
        smartContract: SmartContractObj,
        isWhiteList: isWhiteList,
        isDiscount: isDiscount,
        mintCost: mintCost,
        totalSupply: totalSupply,
        maxSupply: maxSupply,
      })
    );
  };
};
