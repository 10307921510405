import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./blockchain/blockchainReducer";
import blockchainFootballReducer from "./blockchainFootball/blockchainReducer";
import blockchain8BitReducer from "./blockchain8Bit/blockchainReducer";
import blockchainBallsAndBearsReducer from "./blockchainBallsAndBears/blockchainReducer";
import blockchainCockheadzReducer from "./blockchainCockheadz/blockchainReducer";
import dataReducer from "./data/dataReducer";
import walletReducer from "./wallet/walletReducer";

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  blockchainFootball: blockchainFootballReducer,
  blockchain8Bit: blockchain8BitReducer,
  blockchainBallsAndBears: blockchainBallsAndBearsReducer,
  blockchainCockheadz: blockchainCockheadzReducer,
  data: dataReducer,
  wallet: walletReducer,
});

const middleware = [thunk];
const composeEnhancers = compose(applyMiddleware(...middleware));

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers);
};

const store = configureStore();

export default store;
